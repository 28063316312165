window.App = {};

const trackers = {};

if (!App.testing) {
  // Sawmill setup

  sawmillTracker = new _EventTracker.ServiceRegistry();

  this.sawmillService = new _EventTracker.services.Sawmill({
    environment: sawmillOptions.environment,
    applicationName: sawmillOptions.applicationName || 'appointments'
  });
  sawmillTracker.addService('sawmill', this.sawmillService);
  if (sawmillOptions.visitorToken) {
    sawmillTracker.identify(sawmillOptions.visitorToken);
  }

  trackers.sawmill = sawmillTracker;
}

App.Tracker = new window.SimpleTracker(trackers);

App.Tracker.trackPageView();
;