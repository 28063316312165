App.Tracker.trackEvent({
  label: 'minisite_visit',
  action: 'view',
  category: 'widget'
});

class TabGroup {
  constructor(id) {
    this.tabElemsHolder = document.querySelector(`[data-tabs="${id}"]`);
    if (!this.tabElemsHolder) {
      return false;
    }
    this.tabElems = this.tabElemsHolder.querySelectorAll('[data-tab]');
    this.tabContentsHolder = document.querySelector(`[data-tabs-content="${id}"]`);
    if (!this.tabContentsHolder) {
      return false;
    }
    this.tabContentElems = this.tabContentsHolder.querySelectorAll('[data-tab-content]');

    this._activate = this.activate.bind(this);
    this._onActivateEvent = this.onActivateEvent.bind(this);
    this._addEventListeners = this.addEventListeners.bind(this);

    this._addEventListeners();

    this.defaultTab = this.tabElemsHolder.querySelector('[data-tab-default]');
    if (this.defaultTab) {
      this._activate(this.defaultTab);
    }
  }

  addEventListeners() {
    Array.prototype.slice.call(this.tabElems).forEach(tab => {
      tab.addEventListener('click', this._onActivateEvent);
    });
  }

  onActivateEvent(e) {
    e.preventDefault();
    this._activate(e.target);
  }

  activate(newActiveTab) {
    if (newActiveTab === this.activeTab) {
      return;
    }
    this.activeTab = null;

    Array.prototype.slice.call(this.tabElems).forEach(tab => {
      if (tab === newActiveTab) {
        tab.classList.add('tab--active');
        this.activeTab = newActiveTab;
        return;
      }
      tab.classList.remove('tab--active');
    });

    Array.prototype.slice.call(this.tabContentElems).forEach(tabContent => {
      if (tabContent.dataset.tabContent === this.activeTab.dataset.tab) {
        tabContent.classList.add('tab-content--active');
        return;
      }
      tabContent.classList.add('tab-content--inactive');
      tabContent.classList.remove('tab-content--active');
    });
  }
}

new TabGroup('items');

class ModalManager {
  constructor() {
    this.modalTriggerElems = document.querySelectorAll('[data-modal-trigger]');
    this.modalElems = document.querySelectorAll('[data-modal]');

    this._activate = this.activate.bind(this);
    this._onActivateEvent = this.onActivateEvent.bind(this);
    this._addEventListeners = this.addEventListeners.bind(this);

    this._addEventListeners();
  }

  addEventListeners() {
    Array.prototype.slice.call(this.modalTriggerElems).forEach(tab => {
      tab.addEventListener('click', this._onActivateEvent);
    });

    window.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this._activate();
      }
    });
  }

  onActivateEvent(e) {
    e.preventDefault();
    e.stopPropagation();
    this._activate(e.target);
  }

  activate(newActiveModal) {
    if (newActiveModal === this.activeModal) {
      return;
    }
    this.activeModal = null;

    Array.prototype.slice.call(this.modalElems).forEach(modal => {
      if (newActiveModal && modal.dataset.modal === newActiveModal.dataset.modalTrigger) {
        modal.classList.add('modal--active');
        this.activeModal = newActiveModal;
        return;
      }
      modal.classList.remove('modal--active');
    });
  }
}

new ModalManager();

class SeeMoreLessManager {
  // data-see-more-less – Int, chars to show when compressed
  // data-see-more-ellipses – ellipses after compressed textcontent
  // > data-see-more-less-expanded – custom expanded view
  // > data-see-more-less-contracted – custom contracted view
  // > .see-more-less__toggle – toggle container

  constructor() {
    this.toggleableElems = document.querySelectorAll('[data-see-more-less]');

    this._setUp = this.setUp.bind(this);
    this._onToggleEvent = this.onToggleEvent.bind(this);
    this._toggle = this.toggle.bind(this);

    Array.prototype.slice.call(this.toggleableElems).forEach(elem => {
      this._setUp(elem);
    });
  }

  setUp(elem) {
    let elemClone = elem.cloneNode(true);
    const elemDirectChildren = Array.prototype.slice.call(elem.children);
    const elemCloneDirectChildren = Array.prototype.slice.call(elemClone.children);

    if (
      !elemCloneDirectChildren.filter(e => e.matches('[data-see-more-less-contracted]'))[0] &&
      (!isNaN(elem.dataset.seeMoreLessChars) &&
        elemClone.textContent.trim().length < parseInt(elem.dataset.seeMoreLessChars, 10))
    ) {
      // Does not need contraction
      return;
    }

    if (!elemCloneDirectChildren.filter(e => e.matches('[data-see-more-less-expanded]'))[0]) {
      const expandedWrapperElem = document.createElement('div');
      expandedWrapperElem.setAttribute('data-see-more-less-expanded', '');

      if (elemDirectChildren.length) {
        elemDirectChildren.forEach(e => {
          if (e.matches('[data-see-more-less-contracted]') || e.matches('.see-more-less__toggle')) {
            return;
          }
          expandedWrapperElem.appendChild(e);
        });
      } else {
        const innerText = elem.innerText;
        elem.innerText = null;
        expandedWrapperElem.innerText = innerText;
      }
      elem.appendChild(expandedWrapperElem);
    }

    if (!elemCloneDirectChildren.filter(e => e.matches('[data-see-more-less-contracted]'))[0]) {
      const contracted = document.createElement('div');
      contracted.setAttribute('data-see-more-less-contracted', '');
      if (!isNaN(elem.dataset.seeMoreLessChars)) {
        let contractedContent = elemClone.textContent.trim().slice(0, parseInt(elem.dataset.seeMoreLessChars, 10));
        if (elem.dataset.seeMoreLessEllipses) {
          contractedContent = `${contractedContent}…`;
        }
        contracted.textContent = contractedContent;
      } else {
        contracted.appendChild(elemClone);
      }
      elem.appendChild(contracted);
    }

    // See more / See less toggles
    if (!elemCloneDirectChildren.filter(e => e.matches('.see-more-less__toggle'))[0]) {
      const toggleIndicatorElem = document.createElement('span');
      toggleIndicatorElem.setAttribute('class', 'see-more-less__toggle link--browser see-more-less__toggle--inline');
      toggleIndicatorElem.innerText = ' ';

      const expandToggleIndicatorElem = document.createElement('a');
      expandToggleIndicatorElem.setAttribute(
        'class',
        'font--bold see-more-less__toggle-copy see-more-less__toggle--expand'
      );
      expandToggleIndicatorElem.setAttribute('data-see-more-less-toggle', elem.dataset.seeMoreLess);
      expandToggleIndicatorElem.setAttribute('href', '#');
      expandToggleIndicatorElem.textContent = elem.dataset.seeMoreLessExpand;
      toggleIndicatorElem.appendChild(expandToggleIndicatorElem);

      const contractToggleIndicatorElem = document.createElement('a');
      contractToggleIndicatorElem.setAttribute(
        'class',
        'font--bold see-more-less__toggle-copy see-more-less__toggle--contract'
      );
      contractToggleIndicatorElem.setAttribute('data-see-more-less-toggle', elem.dataset.seeMoreLess);
      contractToggleIndicatorElem.setAttribute('href', '#');
      contractToggleIndicatorElem.textContent = elem.dataset.seeMoreLessContract;
      toggleIndicatorElem.appendChild(contractToggleIndicatorElem);

      elem.appendChild(toggleIndicatorElem);
    }

    // Event listeners
    const seeMoreLessToggle = Array.prototype.slice
      .call(elem.children)
      .filter(e => e.matches('.see-more-less__toggle'))[0];
    seeMoreLessToggle.querySelector('.see-more-less__toggle--expand').addEventListener('click', this._onToggleEvent);
    seeMoreLessToggle.querySelector('.see-more-less__toggle--contract').addEventListener('click', this._onToggleEvent);

    elemClone = null;
  }

  onToggleEvent(e) {
    e.preventDefault();
    this._toggle(document.querySelector(`[data-see-more-less="${e.target.dataset.seeMoreLessToggle}"]`));
  }

  toggle(elem) {
    elem.classList.toggle('see-more-less--expanded');
  }
}

new SeeMoreLessManager();

// TODO byron@ update this method
function trackGiftcardEvent(eventName, url) {
  if (App.EsTracker) {
    App.EsTracker.track({
      eventName: eventName,
      eventValue: 'Widget Business Show Giftcard ' + eventName,
      rawData: {
        giftcard_url: url
      }
    })
  }

  if (eventName === 'Click') {
    window.open(url);
    return false;
  }
}
;